<template>
  <div>
    <div class="top-bar-secondary pt-1 pb-5">
      <div class="columns mt-4 is-vcentered is-mobile">
        <div class="column">
          <span v-if="orderDetail" class="is-bold">
            {{ orderDetail.projectName ? orderDetail.projectName + ": " : "" }}
            {{ orderDetail.service.name }}
          </span>
        </div>
        <div v-if="false" class="column is-narrow">
          <b-button type="is-light" outlined>Raise Dispute</b-button>
        </div>
      </div>
    </div>
    <div id="app" class="app-body">
      <div class="p-3">
        <div class="columns">
          <div class="column">
            <div class="spacer-small"></div>
            <router-link :to="{ name: 'Dashboard' }" class="is-dark-gray">
              <b-icon
                icon="arrow-left-thin-circle-outline"
                size="is-medium"
                class="mb-4"
              />
            </router-link>
            <div>
              <div class="columns is-vcentered">
                <div class="column">
                  <p class="is-subtitle is-secondary is-bold">Order Review</p>
                </div>
                <div class="column is-narrow">
                  <b-button
                    @click="openResponsesModal()"
                    type="is-info"
                    icon-left="file"
                  >
                    Show Submitted Brief
                  </b-button>
                </div>
              </div>
            </div>

            <div class="spacer-small"></div>
            <div v-if="loading">
              <Loading />
            </div>
            <div v-else>
              <div v-if="orderDetail.status === 'completed'">
                <b-message type="is-info">
                  <i class="mdi mdi-check mr-1"></i>
                  Order Completed</b-message
                >
                <div class="spacer-small"></div>
              </div>
              <div class="box-outline p-10">
                <div v-if="deliverables.length">
                  <p class="is-subtitle-small is-dark-gray is-bold">
                    Deliverables
                  </p>
                  <hr class="mb-0" />

                  <b-table class="mt-4" :data="deliverables" :hoverable="true">
                    <b-table-column label="Filename" v-slot="props">
                      {{
                        props.row.file.split("/")[
                          props.row.file.split("/").length - 1
                        ]
                      }}
                    </b-table-column>
                    <b-table-column label="Date">
                      {{ new Date().toISOString() | date }}
                    </b-table-column>
                    <b-table-column label="File Type"> .pdf </b-table-column>
                    <b-table-column label="Action" v-slot="props">
                      <a :href="props.row.file">
                        <i class="mdi mdi-cloud-download-outline pr-2"></i>
                        <span>Download</span>
                      </a>
                    </b-table-column>
                  </b-table>
                  <div
                    v-if="orderDetail.status !== 'completed'"
                    class="columns mt-4 is-vcentered"
                  >
                    <div class="column is-narrow">
                      <b-button @click="openCompleteOrderModal()" type="is-info"
                        >Mark as Complete</b-button
                      >
                    </div>
                    <router-link
                      v-if="orderDetail.status !== 'inProgress'"
                      :to="{
                        name: 'RequestRevision',
                        query: { id: orderId },
                      }"
                    >
                      <div class="column is-narrow">
                        <span class="is-secondary has-pointer"
                          >Request Revisions</span
                        >
                      </div>
                    </router-link>
                  </div>
                </div>
                <div v-else>
                  <div class="columns is-vcentered">
                    <div class="column">
                      <p class="is-secondary is-subtitle-small is-bold">
                        Our expert is working on your request
                      </p>
                      <p>Deliverables will appear here once they are ready</p>
                    </div>
                    <div class="column is-narrow has-text-centered">
                      <img
                        src="@/assets/img/misc/order_illustration.svg"
                        width="120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="revisions.length" class="box-outline p-10 mt-4">
                <div class="columns is-vcentered has-pointer">
                  <div @click="toggleRevisions()" class="column">
                    <p class="is-subtitle-small is-dark-gray is-bold">
                      Revisions Requested ({{ revisions.length }})
                    </p>
                  </div>
                  <div @click="toggleRevisions()" class="column is-narrow">
                    <b-icon
                      class="mt-2"
                      :icon="showRevisions ? 'chevron-up' : 'chevron-down'"
                      size="is-medium"
                    ></b-icon>
                  </div>
                </div>
                <div v-if="showRevisions">
                  <hr class="mb-0" />
                  <div v-for="(revision, index) in revisions" :key="index">
                    <b-message type="is-info" has-icon class="mt-4">
                      <div class="columns is-vcentered">
                        <div class="column">
                          <p class="subtitle-small is-bold">Revision</p>
                          {{ revision.text }}
                          <p v-if="revision.file" class="text-small">
                            <a :href="revision.file">
                              Download attached file ({{
                                revision.file.split("/")[
                                  revision.file.split("/").length - 1
                                ]
                              }})
                            </a>
                          </p>
                        </div>
                      </div>
                    </b-message>
                  </div>
                </div>
              </div>
              <ChatBox
                v-if="orderDetail.status !== 'completed'"
                prespective="customer"
              />
              <div class="spacer-small"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals Start -->
    <b-modal v-model="showCompleteOrderModal" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="is-subtitle-small is-dark-gray is-bold">
            Mark Order as Complete
          </p>
        </header>

        <section class="modal-card-body">
          <b-message v-if="false" type="is-info" size="is-small" class="mt-3">
            <i class="mdi mdi-information-outline mr-1"></i>
            This action cannot be undone
          </b-message>
          <p>
            To complete your order, insert the option title, wording, or logo
            option number below to confirm your purchased option.
          </p>
          <b-input v-model="finalSelection" class="mt-2"></b-input>
          <p class="mt-2 text-small">
            For more details, kindly refer to the disclaimer in your Order
            statement, uploaded to your project area.
          </p>
        </section>
        <footer class="modal-card-foot">
          <b-button label="Cancel" @click="closeCompleteOrderModal" />
          <b-button
            type="is-info"
            @click="completeOrder"
            :disabled="finalSelection == ''"
          >
            Confirm
          </b-button>
        </footer>
      </div>
    </b-modal>
    <b-modal v-model="showResponsesModal" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="is-subtitle-small is-dark-gray is-bold">
            Submitted Responses
          </p>
        </header>
        <section v-if="responses" class="modal-card-body">
          <div v-for="(response, index) in responses" :key="index">
            <p class="is-bold is-secondary">{{ response.q }}</p>
            <p
              v-if="
                typeof response.r === 'string' &&
                response.r.slice(0, 5) == 'FILE#'
              "
              class="mt-2 mb-4"
            >
              <a
                v-if="response.r != 'FILE#undefined'"
                :href="response.r.split('#')[1]"
              >
                <b-button icon-left="download"> Download File </b-button>
              </a>
              <a v-else href="#" class="has-text-info">
                <i class="mdi mdi-information-outline mr-1"></i>
                No file uploaded
              </a>
            </p>
            <p
              v-else-if="
                typeof response.r === 'string' &&
                response.r.slice(0, 4) == 'IMG#'
              "
              class="mt-2 mb-4 has-text-centered"
            >
              <ResponseImageViewer :image="response.r.split('#')[1]" />
            </p>
            <p
              v-else-if="
                Array.isArray(response.r) &&
                response.r.length &&
                response.r[0].slice(0, 4) == 'IMG#'
              "
              class="mt-2 mb-4 has-text-centered"
            >
              <ResponseImageViewer
                v-for="(response, index) in response.r"
                :key="index"
                :image="response.split('#')[1]"
              />
            </p>
            <p
              v-else-if="
                Array.isArray(response.r) &&
                response.r.length &&
                response.r[0].slice(0, 5) == 'FILE#'
              "
              class="mt-2 mb-4"
            >
              <a
                v-for="(response, index) in response.r"
                :key="index"
                :href="
                  response.r[0] != 'FILE#undefined'
                    ? response.split('#')[1]
                    : '#'
                "
              >
                <b-button
                  v-if="response.r[0] != 'FILE#undefined'"
                  icon-left="download"
                >
                  Download File
                </b-button>
                <p v-else>
                  <i class="mdi mdi-information-outline mr-1"></i> No file
                  uploaded
                </p>
              </a>
            </p>
            <p
              v-else-if="Array.isArray(response.r) && response.r.length"
              class="mt-2 mb-4"
            >
              {{ response.r.join(", ") }}
            </p>
            <p
              v-else-if="
                typeof response.r === 'string' &&
                response.r.slice(0, 4) == 'IMG#'
              "
              class="mt-2 mb-4"
            >
              <ResponseImageViewer :image="response.r.split('#')[1]" />
            </p>
            <p v-else class="mb-4">{{ response.r }}</p>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button label="Close" @click="closeResponsesModal" />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Loading from "../../components/Loading.vue";
import ChatBox from "../../components/ChatBox.vue";
import ResponseImageViewer from "../../components/misc/ResponseImageViewer.vue";

export default {
  name: "OrderDetail",
  components: { Loading, ChatBox, ResponseImageViewer },
  data() {
    return {
      loading: false,
      showRevisions: false,
      showCompleteOrderModal: false,
      showResponsesModal: false,
      finalSelection: "",
    };
  },
  computed: {
    orderId() {
      return this.$route.query.orderId;
    },
    orderDetail() {
      return this.$store.state.orderDetail;
    },
    deliverables() {
      return this.$store.state.orderDetail
        ? this.$store.state.orderDetail.deliverables
        : undefined;
    },
    revisions() {
      return this.$store.state.orderDetail
        ? this.$store.state.orderDetail.revisions
        : undefined;
    },
    responses() {
      if (this.$store.state.orderDetail) {
        const questions = Object.values(
          this.$store.state.orderDetail.questions
        );
        const responses = Object.values(
          this.$store.state.orderDetail.responses
        );
        const res = [];
        for (let i = 0; i < questions.length; i++) {
          const pair = {
            q: questions[i] || undefined,
            r: responses[i] || undefined,
          };
          res.push(pair);
        }
        return res;
      } else {
        return undefined;
      }
    },
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("getOrderDetail", this.orderId);
    this.loading = false;
  },
  methods: {
    toggleRevisions() {
      this.showRevisions = !this.showRevisions;
    },
    openCompleteOrderModal() {
      this.showCompleteOrderModal = true;
    },
    closeCompleteOrderModal() {
      this.showCompleteOrderModal = false;
    },
    openResponsesModal() {
      this.showResponsesModal = true;
    },
    closeResponsesModal() {
      this.showResponsesModal = false;
    },

    async completeOrder() {
      await this.$store.dispatch("completeOrder", {
        orderId: this.orderId,
        finalSelection: this.finalSelection,
      });
      this.closeCompleteOrderModal();
      this.loading = true;
      await this.$store.dispatch("getOrderDetail", this.orderId);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
